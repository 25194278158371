export const menuItems = [
    /*{
        id: 1,
        //label: "menuitems.menu.text",
        //isTitle: true
    },*/
    //DERNIER ID EST 34
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "1"
        },
        link: '/',
    },
    {
        id: 2,
        label: 'menuitems.menu_demande.text',
        isTitle: true,
        groupe: 2,
    },
    {
        id: 3,
        label: 'menuitems.liste_demande.text',
        icon: 'fa fa-list',
        link: '/demande/list',
        groupe: 2,
    }, {
        id: 16,
        label: 'menuitems.demandes_particulier.text',
        icon: 'fa fa-list',
        link: '/demande/demandes_particulier',
        groupe: 2,
    },
    {
        id: 4,
        label: 'menuitems.menu_agence.text',
        isTitle: true,
        groupe: 3,
    },
    {
        id: 5,
        label: 'menuitems.list_agence.text',
        icon: 'fa fa-list',
        link: '/agence/list',
        groupe: 3,
    },
    {
        id: 15,
        label: 'menuitems.list_utilisateur.text',
        icon: 'fa fa-user',
        link: '/agence/list_utilisateur',
        groupe: 3,
    },
    
    {
        id: 6,
        label: 'menuitems.menu_abonnement.text',
        isTitle: true,
        groupe: 4,
    },
    {
        id: 8,
        label: 'menuitems.liste_abonne.text',
        icon: 'fa fa-list',
        link: '/abonnement/list',
        groupe: 4,
    },
    {
        id: 9 ,
        label: 'menuitems.historique_abonnement.text',
        icon: 'fa fa-history',
        link: '/abonnement/historique',
        groupe: 4,
    },
    {
        id: 10,
        label: 'menuitems.menu_transactions.text',
        isTitle: true,
        groupe: 5,
    },
    {
        id: 11,
        label: 'menuitems.transac_reservation.text',
        icon: 'fa fa-credit-card',
        link: '/transaction/virement',
        groupe: 5,
    },
    {
        id: 12,
        label: 'menuitems.remboursement.text',
        icon: 'fab fa-cc-visa',
        link: '/transaction/remboursement',
        groupe: 5,
    },
    {
        id: 13,
        label: 'menuitems.menu_admin.text',
        isTitle: true,
        groupe: 6,
    },
    {
        id:14,
        label:'Ajouter',
        icon:'fa fa-plus',
        link:'/admin/add-admin',
        groupe:6,
    }
    //Trajets
   /*  {
        id: 2,
        label: 'menuitems.menu_trajets.text',
        isTitle: true,
        groupe: 1,
    },
    {
        id: 3,
        label: 'Ajouter',
        icon: 'fa fa-plus',
        link: '/newtrajet',
        groupe: 1,
    },
    {
        id: 4,
        label: 'Publiés',
        icon: 'fas fa-map-marked-alt',
        link: '/trajets/publies',
        groupe: 1,
    },
    {
        id: 5,
        label: 'menuitems.encours.text',
        icon: 'fas fa-spinner',
        link: '/trajets/encours',
        groupe: 1,
    },

    {
        id: 6,
        label: 'menuitems.avenirs.text',
        icon: 'fas fa-map-marker-alt',
        link: '/trajets/avenir',
        groupe: 1,
    },
    {
        id: 7,
        label: 'menuitems.effectues.text',
        icon: 'fas fa-map-marked-alt',
        link: '/trajets/effectues',
        groupe: 1,
    },
    {
        id: 8,
        label: 'menuitems.annules.text',
        icon: 'fas fa-ban',
        link: '/trajets/annules',
        groupe: 1,
    },
    {
        id: 9,
        label: 'menuitems.alerte_trajet.text',
        icon: 'fas fa-exclamation-triangle',
        link: '/trajets/alertes',
        groupe: 1,
    },
    {
        id: 10,
        label: 'menuitems.urgences.text',
        icon: 'fas fa-exclamation-triangle',
        link: '/trajets/urgences',
        groupe: 1,
    }, */

    //Réservation
    /*{
        id: 11,
        label: 'menuitems.menu_reservation.text',
        isTitle: true,
        groupe: 2,
    },
    {
        id: 12,
        label: 'menuitems.add_reservation.text',
        icon: 'fas fa-calendar-day',
        link: '/reservation/add_reservation',
        groupe: 2,
    },
    {
        id: 13,
        label: 'menuitems.list_reservation.text',
        icon: 'fas fa-list',
        link: '/reservation/reservation_list',
        groupe: 2,
    },*/

    //Utilisateur
    /* {
        id: 14,
        label: 'menuitems.menu_utilisateurs.text',
        isTitle: true,
        groupe: 3,
    },
    {
        id: 15,
        label: 'menuitems.ajouter.text',
        icon: 'fas fa-user-plus',
        link: '/user/add_users',
        groupe: 3,
    },
    {
        id: 16,
        label: 'menuitems.liste.text',
        icon: 'fas fa-users',
        link: '/user/users_list',
        groupe: 3,
    },
    {
        id: 17,
        label: 'menuitems.statistique.text',
        icon: 'fas fa-chart-bar',
        link: '/user/statistique',
        groupe: 3,
    },
    {
        id: 23,
        label: 'Signalé',
        icon: 'fas fa-info-circle ',
        link: '/alertes/details_alertes',
        groupe: 3, 
    }, */
    //don
    /* {
        id: 18,
        label: 'menuitems.menu_dons.text',
        isTitle: true,
        groupe: 4,
    },
    {
        id: 19,
        label: 'menuitems.ajouter_dons.text',
        icon: 'fas fa-plus',
        link: '/dons/add_dons',
        groupe: 4,
    },
    {
        id: 35,
        label: 'menuitems.liste_dons.text',
        icon: 'fas fa-list',
        link: '/dons/list_dons',
        groupe: 4,
    }, */
    //bons plan
   /*  {
        id: 20,
        label: 'menuitems.menu_bons_plans.text',
        isTitle: true,
        groupe: 5,
    },
    {
        id: 21,
        label: 'menuitems.bon-plan.text',
        icon: 'fas fa-plus ',
        link: '/bon_plans/bons_plans',
        groupe: 5,
    }, */
    //alertes
    /*{
        id: 22,
        label: 'Signalé',
        isTitle: true,
        groupe: 6,
    },
    {
        id: 23,
        label: 'Liste',
        icon: 'fas fa-info-circle ',
        link: '/alertes/details_alertes',
        groupe: 6, 
    },*/
    //transactions
    /* {
        id: 24,
        label: 'menuitems.menu_transactions.text',
        isTitle: true,
        groupe: 7, 
    },
    {
        id: 25,
        label: 'menuitems.historique.text',
        icon: 'fas fa-history',
        link: '/transactions/historique-transactions',
        groupe: 7, 
    }, */
    //gains
   /*  {
        id: 26,
        label: 'menuitems.menu_gains.text',
        isTitle: true,
        groupe: 8, 
    },
    {
        id: 27,
        label: 'menuitems.historique_gain.text',
        icon: 'fas fa-history ',
        link: '/suivis/historique',
        groupe: 8, 
    },
    //virements
    {
        id: 28,
        label: 'menuitems.menu_virement.text',
        isTitle: true,
        groupe: 9, 
    },
    {
        id: 29,
        label: 'menuitems.historique_virement.text',
        icon: 'fas fa-history ',
        link: '/virements/historique-virements',
        groupe: 9,  
    }, */
    //administrateur
    /* {
        id: 30,
        label: 'menuitems.menu_administrateur.text',
        isTitle: true,
        groupe: 10, 
    },
    {
        id: 31,
        label: 'menuitems.admin.text',
        icon: 'fas fa-lock ',
        link: '/admin/add-admin',
        groupe: 10,  
    }, */
    
    //Message
   /*  {
        id: 32,
        label: 'Messages',
        isTitle: true,
        groupe: 11, 
    },
    {
        id: 33,
        label: 'Nouveau',
        icon: 'ri-mail-add-fill',
        link: '/message/nouveau',
        groupe: 11, 
    },
    {
        id: 34,
        label: 'Historique',
        icon: 'ri-mail-check-line',
        link: '/message/historique',
        groupe: 11,  
    }, */
    //Code Promo
   /*  {
        id: 35,
        label: 'Code Promo',
        isTitle: true,
        groupe: 11, 
    },
    {
        id: 36,
        label: 'Promos',
        icon: 'fas fa-list',
        link: '/codepromo',
        groupe: 11, 
    }, */

    /* {
        id: 39,
        label: 'menuitems.menu_agence.text',
        isTitle: true,
        groupe: 11, 
    },
    {
        id: 43,
        label: 'menuitems.ajout_agence.text',
        icon: 'fas fa-ca',
        link: '/agence/add',
        groupe: 12, 
    },
    {
        id: 44,
        label: 'menuitems.list_agence.text',
        icon: 'fas fa-ca',
        link: '/agence/list',
        groupe: 12, 
    }, */

  
]


