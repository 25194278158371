<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
        {{ this.currentYear }}  © RMobility.
        </div>
        <!--<div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Themesdesign
          </div>
        </div>-->
      </div>
    </div>
  </footer>
</template>
